import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, of, tap } from 'rxjs';
import { API_URL } from '../constants/api-urls.const';

export interface ITokenData {
  token: string;
  refreshToken: string;
  expirationDate: number;
  expirationRefreshTokenDate: number;
}

const mockTokenData = () => {
  return {
    token: 'accessToken',
    refreshToken: 'refreshToken',
    expirationDate: Date.now() + 1000000,
    expirationRefreshTokenDate: Date.now() + 10000000,
  };
};

export interface IRegisterUserData {
  email: string;
  name: string;
  password: string;
}

export interface IApiToken {
  token: {
    access_token: string;
    expires_in: number;
    jti: string;
    refresh_token: string;
    scope: 'user-client';
    token_type: 'bearer';
  };
  userId: number;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient) {
    console.log('hi');
  }

  login(registerUserDate: IRegisterUserData): Observable<ITokenData> {
    // console.log('login');
    // return this.http.post<ITokenData>(API_URL.signUp, registerUserDate);
    return of(mockTokenData()).pipe(
      tap(tokenData => {
        localStorage.setItem('tokenData', JSON.stringify(tokenData));
        this.router.navigate(['']);
      }),
    );
  }

  loginViaGoogle(googleToken: string): void {
    this.http.get<IApiToken>(`${API_URL.loginViaGoogle}?token=${googleToken}`).subscribe(res => {
      localStorage.setItem(
        'tokenData',
        JSON.stringify({
          token: res.token.access_token,
          refreshToken: res.token.refresh_token,
          expirationDate: Date.now() + res.token.expires_in * 1000,
          expirationRefreshTokenDate: Date.now() + res.token.expires_in * 10000,
        }),
      );
      this.router.navigate(['']);
    });
  }

  logout(): Observable<boolean> {
    console.log('logout');
    return of(false).pipe(tap(() => localStorage.removeItem('tokenData')));
  }

  refreshToken(): Observable<ITokenData> {
    console.log('refreshToken');
    return of(mockTokenData()).pipe(tap(tokenData => localStorage.setItem('tokenData', JSON.stringify(tokenData))));
  }

  // registration():Observable<string> {
  //   return of('accessToken').pipe(tap(token => localStorage.setItem('accessToken', token)));
  // }

  isValidToken(): Observable<boolean> {
    const tokenData: ITokenData = JSON.parse(localStorage.getItem('tokenData') || '{}');

    if (tokenData?.token && tokenData.refreshToken && tokenData.expirationRefreshTokenDate > Date.now()) {
      if (tokenData.expirationDate > Date.now()) {
        return of(true);
      } else {
        return this.refreshToken().pipe(map(res => !!res));
      }
    } else {
      return this.logout();
    }
  }
}
