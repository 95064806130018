import { Component, OnInit } from '@angular/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private nzConfigService: NzConfigService) {}

  ngOnInit(): void {
    console.log();
    // this.changeTheme();
    // console.log(this.nzConfigService.getConfig());
  }

  // changeTheme() {
  // this.nzConfigService.set('theme', { primaryColor: '#000000' });
  // }
}
