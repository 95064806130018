import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

const routes: Routes = [
  // { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module'),
    // loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [authGuard],
  },
  {
    path: '',
    loadChildren: () => import('./modules/cabinet/cabinet.module'),
    // loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
