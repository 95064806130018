import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs';

export const authGuard: CanActivateFn = route => {
  const router = inject(Router);
  return inject(AuthService)
    .isValidToken()
    .pipe(
      map((isTokenValid: boolean) => {
        // console.log(route.routeConfig?.path);
        const guardCheck = (isTokenValid && route.routeConfig?.path === '') || (!isTokenValid && route.routeConfig?.path === 'auth');
        // console.log(guardCheck, route.routeConfig?.path);
        if (!guardCheck) {
          router.navigate([route.routeConfig?.path === '' ? 'auth' : '']);
        }
        return guardCheck;
      }),
    );
};
